<template>
	<screen size="medium" class="register-view">
		<template slot="body">
			<div class="register-header">
				<div class="roundup-logo"></div>
				<h4>Let's get started. Who are you?</h4>
				<p>
					Fill out all the fields below to get the application process
					started.
				</p>
			</div>
			<div class="register-content">
				<form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input
							v-model="user.first_name"
							placeholder="First Name"
						/>
					</ui-label>
					<ui-label>
						<ui-input
							v-model="user.last_name"
							placeholder="Last Name"
						/>
					</ui-label>
					<ui-label>
						<ui-input v-model="user.email" placeholder="Email" />
					</ui-label>
					<ui-label>
						<ui-input
							type="number"
							v-model="user.phone"
							placeholder="Cell Phone"
						></ui-input>
					</ui-label>
					<ui-label>
						<ui-input
							v-model="user.password"
							placeholder="Password"
							type="password"
						/>
					</ui-label>
					<ui-label>
						<ui-input
							v-model="user.name"
							placeholder="Store Name"
						></ui-input>
					</ui-label>
					<ui-label>
						<ui-input
							v-model="user.url"
							placeholder="Website URL"
						/>
					</ui-label>
					<ui-label
						v-bind:style="{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							paddingBottom: '0px'
						}"
					>
						<ui-toggle
							v-bind:style="{ flex: 1 }"
							@input="checked('email_subscribe')"
							:checked="user.email_subscribe"
						>
						</ui-toggle>
						<p v-bind:style="{ flex: 4 }">
							I would like to receive updates and news about
							RoundUp App.
						</p>
					</ui-label>
					<ui-label
						v-bind:style="{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}"
					>
						<ui-toggle
							v-bind:style="{ flex: 1 }"
							@input="checked('tos_accept')"
							:checked="user.tos_accept"
						>
						</ui-toggle>
						<p v-bind:style="{ flex: 4 }">
							<strong>Agreement:</strong> I have read and agree to
							the
							<a
								target="_blank"
								href="https://roundupapp.com/terms/"
								>Terms of Use</a
							>
							and
							<a
								target="_blank"
								href="https://roundupapp.com/platform-merchant/"
								>Platform Agreement</a
							>
						</p>
					</ui-label>
					<ui-label>
						<ui-button native-type="submit" :loading="isLoading"
							>Submit</ui-button
						>
					</ui-label>
				</form>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiToggle from '../../ui/Toggle'
import Screen from '../../ui/Screen'

export default {
	name: 'register-view',
	components: { UiButton, UiInput, UiLabel, UiToggle, Screen },
	data() {
		return {
			isLoading: false,
			user: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				name: '',
				url: '',
				phone: '',
				email_subscribe: true,
				tos_accept: false,
				role: 1
			},
			institution: null
		}
	},
	methods: {
		...mapActions(['register']),
		onSubmit() {
			this.isLoading = true
			this.register(this.user)
				.then(data => {
					this.isLoading = false
					this.$router.push({ name: 'steps' })
				})
				.catch(error => {
					this.isLoading = false
					this.$swal(error.message)
				})
		},
		checked(name) {
			this.user[name] = !this.user[name]
		}
	},
	mounted() {
		const storeInfo = JSON.parse(sessionStorage.getItem('store_info'))
		if (storeInfo) {
			this.user.first_name = storeInfo.firstname
			this.user.last_name = storeInfo.lastname
			this.user.url = storeInfo.domain
			this.user.email = storeInfo.email
			this.user.name = storeInfo.name
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.register-view {
	padding: 60px 60px 40px 60px;
	text-align: left;
	.register-header {
		p {
			margin: 0px;
		}
	}
	.roundup-logo {
		background: url('../../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 14px;
		margin: 0px 0px 20px;
	}
}
</style>
