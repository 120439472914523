var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticClass:"register-view",attrs:{"size":"medium"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"register-header"},[_c('div',{staticClass:"roundup-logo"}),_c('h4',[_vm._v("Let's get started. Who are you?")]),_c('p',[_vm._v(" Fill out all the fields below to get the application process started. ")])]),_c('div',{staticClass:"register-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"First Name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Last Name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"type":"number","placeholder":"Cell Phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Store Name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Website URL"},model:{value:(_vm.user.url),callback:function ($$v) {_vm.$set(_vm.user, "url", $$v)},expression:"user.url"}})],1),_c('ui-label',{style:({
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingBottom: '0px'
					})},[_c('ui-toggle',{style:({ flex: 1 }),attrs:{"checked":_vm.user.email_subscribe},on:{"input":function($event){return _vm.checked('email_subscribe')}}}),_c('p',{style:({ flex: 4 })},[_vm._v(" I would like to receive updates and news about RoundUp App. ")])],1),_c('ui-label',{style:({
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					})},[_c('ui-toggle',{style:({ flex: 1 }),attrs:{"checked":_vm.user.tos_accept},on:{"input":function($event){return _vm.checked('tos_accept')}}}),_c('p',{style:({ flex: 4 })},[_c('strong',[_vm._v("Agreement:")]),_vm._v(" I have read and agree to the "),_c('a',{attrs:{"target":"_blank","href":"https://roundupapp.com/terms/"}},[_vm._v("Terms of Use")]),_vm._v(" and "),_c('a',{attrs:{"target":"_blank","href":"https://roundupapp.com/platform-merchant/"}},[_vm._v("Platform Agreement")])])],1),_c('ui-label',[_c('ui-button',{attrs:{"native-type":"submit","loading":_vm.isLoading}},[_vm._v("Submit")])],1)],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }